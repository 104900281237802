.spinner-header {
    text-align: center;
    padding-top: 30px;
    font-family: "Barlow";
}

.modal-header {
    border-bottom: none;
}

.spinner {
    width: 200px; 
    height: 200px;
    text-align: center;
}

.lottie-media {
    width: 200px; 
    height: 200px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.modal-footer {
    border-top: none;
}