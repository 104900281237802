h4 {
  font-family: "Roboto";
  font-weight: 400;
  padding-bottom: 20px;
}

ul.about-us {
  list-style-type: none;
  font-weight: 400;
}

a:hover {
  color: #0878A3;
}

#about-us {
  text-align: left;
  padding: 2% 25% 5%;
}

.about-us .active-page {
  font-weight: 500;
}

.aboutus-column {
  padding-top: 0;
}

.aboutus-title {
  padding-bottom: 5%;
  font-family: "Montserrat";
  font-weight: 500;
}

.aboutus-statement {
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 20px;
}
