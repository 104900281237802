h2 {
  font-family: "Roboto";
  font-weight: 600;
  padding-bottom: 20px;
}

.contact-body {
  background-color: #fff;
  margin: 0 15%;
  text-align: left;
  padding: 40px 30px 10px;
}

/* Contact Info Section */
.info {
  /* border-bottom: 10px solid #005792; */
}

.email {
  margin-bottom: 50px;
}

/* Form section */
.label::after {
  content: "*";
  color: red;
  padding-left: .2rem;
}

.submit {
  background-color: #752D3B;
  color: #fff;
}

#form {
  padding-bottom: 70px;
}
