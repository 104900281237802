.services-body {
  padding: 10px 25% 30px;
}

.service {
  text-align: left;
}

ul {
  margin-top: 10px;
}