body {
  background-color: #fff;
  text-align: center;
}

h1 {
  font-family: "Roboto";
  font-weight: 800;
}

p {
  font-family: "Barlow";
  font-weight: 400;
}

ul {
  font-family: "Barlow";
  font-weight: 300;
}

.breadcrumb-link {
  color: inherit;
}

.active-page {
  color: inherit;
}

.nonactive-page {
  color: #752D3B;
}

.container-fluid {
  padding: 7% 15%;
}

.header-image {
  width: 15%;
  padding-right: 10px;
}

.navbar {
  font-family: "Montserrat";
  background-color: #0878A3;
  outline: 10px solid #752D3B;
  padding: 0 15% 0;
}

.navbar-brand {
  font-family: "Ubuntu";
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
}

.nav-link {
  text-align: right;
  font-family: "Montserrat";
  font-size: 1.2rem;
  font-weight: 300;
}

.nav-item {
  padding: 0 18px;
}

.navbar ul {
  margin-top: 0;
}

.dropdown-item:hover {
  background-color: #752D3B;
  color: #fff;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  background-color: #f4f6ff;
  margin-left: 50%
}

/* title */

/* Carousel */
.title-image {
  display: block;
  width: 100%;
  height: 500px;
  margin: 10px 0 0;
}


.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23752D3B' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23752D3B' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

/* Introduction */
#introduction {
  background-color: #fff;
  margin: 0 15% 0;
}

.intro {
  padding: 5% 0;
  margin-left: auto;
  margin-right: auto;
}

.intro-header {
  border-left: 1rem solid #752D3B;
  padding-left: 1rem;
  text-align: left;
}

.intro p {
  padding-left: 2rem;
  padding-right: 25%;
  padding-bottom: 20px;
  text-align: left;
  font-size: 22px;
}

/* Services */
#Services {
  background-color: #0878A3;
  padding: 20px 15% 10px;
}

.service-box {
  text-align: center;
  font-family: "Barlow";
  color: #fff;
}

.services-header {
  border-left: 1rem solid #752D3B;
  padding-left: 1rem;
  margin-bottom: 20px;
  text-align: left;
}

.fa-arrow-right {
  margin-left: 5px;
}

.service-icon {
  margin-bottom: 1rem;
}

.bct {
  height: 90px;
  width: 120px;
}

.service-name {
  color: #fff;
}

.service-name:hover {
  color: #752D3B;
}

.service-description {
  padding: 20px 50px;
}

/* Affiliates section */
#affiliates {
  background-color: #fff;
}

.affiliates-icon {
  width: 50%;
  padding: 5%;
  height: 50%;
}

/* Contact Section */
#contact {
  text-align: left;
  background-color: #f4f6ff;
}

#contact .container-fluid {
  padding: 30px 15%;
}

.contact-button {
  background-color: #752D3B;
}


/* Footer Section */
#footer {
  background-color: #1f1f1f;
  color: #fff;
  font-family: "Barlow";
  padding: 10px;
}
